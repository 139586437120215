<template>

<div class="container-fluid ">
		<div class="row ecart-signup justify-content-center bg-black h-100-vh">
			<div class="col-12 col-sm-8 col-md-6 col-lg-5 d-flex flex-column justify-content-center bg-white">
        <div class="p-2 p-lg-5 m-xl-4 animated fadeIn text-center">
          <i class=" fa fa-spinner fa-spin fa-4x my-4"></i>
              <h3 class="f-34 f-w-600 my-4" v-text="$t('general.loggingOut')"> </h3>
        </div>
			</div>
		</div>
	</div>

</template>

<script>
  import {
    mapState,
    mapActions,
    mapMutations
  } from 'vuex';

  export default {

    methods: {
      ...mapActions(['fnWSDisconnect']),
      ...mapMutations('EcartNotifications', ['changeNotifications']),
    },

    mounted() {
      document.getElementsByTagName('body')[0].classList.remove('modal-open');
      this.changeNotifications(false);
      this.fnWSDisconnect();
      this.$store.commit('menu/setStepNumber', 0);
      setTimeout(() => {
        this.$store.dispatch('session/fnLogout');
      }, 500);
    }
  }
</script>

<style>

</style>